import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpContext,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  IDAM_TOKEN,
  REDIRECT_ON_UNAUTHORIZE,
  REFRESH_TOKEN,
} from 'src/app/constants';
import { UtilService } from './util.service';
import { LocationService } from './location.service';
import {
  RetailIDAMRefreshTokenResponse,
  RetailIDAMResponse,
} from '../interfaces/idam-auth.interfaces';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface AuthInfo {
  jwt?: string;
  accessTokenExpiresAt?: number;
  refreshToken?: string;
  refreshTokenExpiresAt?: number;
  [key: string]: any;
}

interface JcnInfo {
  jwt?: string;
  [key: string]: any;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private authInfo: AuthInfo = {};
  private jcnInfo: JcnInfo = {};
  public isLoggedIn$ = new BehaviorSubject<boolean>(false);
  public redirectURL?: string;
  private fetchingRefreshToken = false;

  constructor(
    private util: UtilService,
    private http: HttpClient,
    private locationService: LocationService
  ) {}

  setAuthInfo(info: AuthInfo) {
    this.authInfo = info;
    localStorage['authInfo'] = JSON.stringify(info);
  }

  setJcnInfo(info: JcnInfo) {
    this.jcnInfo = info;
    localStorage['jcnInfo'] = JSON.stringify(info);
  }

  getAuthInfo(): AuthInfo {
    const authInfo = localStorage['authInfo'] as string;
    if (authInfo) {
      return JSON.parse(authInfo) as AuthInfo;
    }
    return this.authInfo;
  }

  getJcnInfo(): JcnInfo {
    const jcnInfo = localStorage['jcnInfo'] as string;
    if (jcnInfo) {
      return JSON.parse(jcnInfo) as JcnInfo;
    }
    return this.jcnInfo;
  }

  getIsAuthenticated(): boolean {
    const authInfo = this.getAuthInfo();
    return !!authInfo.jwt;
  }

  getJcnAuthenticated(): boolean {
    const jcnInfo = this.getJcnInfo();
    return !!jcnInfo.jwt;
  }

  setAuthToken(token: string) {
    this.authInfo.jwt = token;
  }

  setJcnToken(token: string) {
    this.jcnInfo.jwt = token;
  }

  getAuthToken(): string {
    const authInfo = this.getAuthInfo();
    return authInfo.jwt ? authInfo.jwt : '';
  }

  getJcnToken(): string {
    const jcnInfo = this.getJcnInfo();
    return jcnInfo.jwt ? jcnInfo.jwt : '';
  }

  logout() {
    this.isLoggedIn$.next(false);
    this.setAuthInfo({});
    this.setJcnInfo({});
    this.clearIDAMAuthToken();
    this.clearMonthlyReportToken();
    this.locationService.clearLocationInfo();
  }

  getRefreshToken() {
    const authInfo = this.getAuthInfo();
    return authInfo.refreshToken ?? '';
  }

  setIDAMAuthToken(token: string): void {
    localStorage.setItem('idamAccessToken', token);
  }

  getIDAMAuthToken(): string {
    return localStorage.getItem('idamAccessToken') || '';
  }

  clearIDAMAuthToken(): void {
    localStorage.removeItem('idamAccessToken');
  }

  setMonthlyReportToken(token: string) {
    localStorage.setItem('monthlyReportToken', token);
  }

  getMonthlyReportToken(): string {
    return localStorage.getItem('monthlyReportToken') as string;
  }

  clearMonthlyReportToken() {
    localStorage.removeItem('monthlyReportToken');
  }

  validateIDAMSession(
    clientId: string,
    idamAccessToken: string
  ): Observable<{ success: boolean }> {
    if (
      !environment.retailIDAMBackendURL ||
      !environment.clientIdForRetailIDAM
    ) {
      console.log(
        'Please check the configuration and try again. IDAM not enabled'
      );
      return of({ success: false });
    }

    const url = `${environment.retailIDAMBackendURL}/service/application/retail-auth/v2.0/validate-token`;

    const headers = new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      client_id: clientId,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Authorization: `Bearer ${idamAccessToken}`,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/json',
    });

    return this.http
      .post<RetailIDAMResponse>(
        url,
        {},
        {
          headers,
          context: new HttpContext()
            .set(IDAM_TOKEN, true)
            .set(REDIRECT_ON_UNAUTHORIZE, false),
        }
      )
      .pipe(
        map(() => {
          return { success: true };
        }),
        catchError((err: HttpErrorResponse) => {
          if (err.status === 401) {
            console.error(
              'Unauthorized - Invalid credentials or authorization issue',
              err.error
            );
          } else if (err.status === 400) {
            console.error(
              'Bad Request - Possibly invalid parameters',
              err.error
            );
          }
          return of({ success: false });
        })
      );
  }

  refreshAccessToken() {
    const url = `${environment.apiHost}/auth/phone/refresh-token`;

    return this.http
      .post<RetailIDAMRefreshTokenResponse>(
        url,
        {},
        {
          context: new HttpContext()
            .set(REFRESH_TOKEN, true)
            .set(REDIRECT_ON_UNAUTHORIZE, false),
        }
      )
      .pipe(
        map((response) => {
          this.setAuthToken(response.accessToken);
          this.setAuthInfo({
            jwt: response.accessToken,
            accessTokenExpiresAt:
              new Date().getTime() + response.accessTokenExpiresIn * 1000,
            refreshToken: response.refreshToken,
            refreshTokenExpiresAt:
              new Date().getTime() + response.refreshTokenExpiresIn * 1000,
            info: this.util.parseJWT(response.accessToken),
          });

          this.fetchingRefreshToken = false;
          return { success: true };
        }),
        catchError((err: HttpErrorResponse) => {
          if (err.status === 401) {
            console.error(
              'Unauthorized - Invalid credentials or authorization issue',
              err.error
            );
          } else if (err.status === 400) {
            console.error(
              'Bad Request - Possibly invalid parameters',
              err.error
            );
          }
          this.fetchingRefreshToken = false;

          return of({ success: false });
        })
      );
  }

  refreshIdamAccessToken() {
    const url = `${environment.apiHost}/auth/retail-idam/refresh-token`;

    return this.http
      .post<RetailIDAMRefreshTokenResponse>(
        url,
        {},
        {
          context: new HttpContext()
            .set(REFRESH_TOKEN, true)
            .set(REDIRECT_ON_UNAUTHORIZE, false),
        }
      )
      .pipe(
        map((response) => {
          this.setIDAMAuthToken(response.idamAccessToken);
          this.setAuthToken(response.accessToken);
          this.setAuthInfo({
            jwt: response.accessToken,
            accessTokenExpiresAt:
              new Date().getTime() + response.accessTokenExpiresIn * 1000,
            refreshToken: response.refreshToken,
            refreshTokenExpiresAt:
              new Date().getTime() + response.refreshTokenExpiresIn * 1000,
            info: this.util.parseJWT(response.accessToken),
          });

          this.fetchingRefreshToken = false;
          return { success: true };
        }),
        catchError((err: HttpErrorResponse) => {
          if (err.status === 401) {
            console.error(
              'Unauthorized - Invalid credentials or authorization issue',
              err.error
            );
          } else if (err.status === 400) {
            console.error(
              'Bad Request - Possibly invalid parameters',
              err.error
            );
          }
          this.fetchingRefreshToken = false;

          return of({ success: false });
        })
      );
  }

  validateSession(): Observable<boolean> {
    const clientId = environment.clientIdForRetailIDAM;
    const authInfo = this.getAuthInfo();
    const currentDateTime = new Date().getTime();

    if (!environment.retailIDAMBackendURL || !clientId) {
      if (
        !authInfo ||
        !authInfo.accessTokenExpiresAt ||
        !authInfo.refreshTokenExpiresAt ||
        !authInfo.refreshToken
      ) {
        this.logout();
        return of(false);
      }

      if (currentDateTime < authInfo.accessTokenExpiresAt) {
        return of(true);
      } else if (currentDateTime < authInfo.refreshTokenExpiresAt) {
        if (!this.fetchingRefreshToken) {
          this.fetchingRefreshToken = true;

          return this.refreshAccessToken().pipe(
            map((response) => {
              const isValid = response.success;
              if (!isValid) {
                this.logout();
              }

              return isValid;
            }),
            catchError(() => {
              this.logout();

              return of(false);
            })
          );
        } else {
          return new Observable<boolean>((observer) => {
            let count = 0;
            const interval = setInterval(() => {
              if (count > 10 || !this.fetchingRefreshToken) {
                clearInterval(interval);
                observer.next(true);
                observer.complete();
              }

              count += 1;
            }, 200);
          });
        }
      } else {
        this.logout();
        return of(false);
      }
    }

    const idamAccessToken = this.getIDAMAuthToken();

    if (!idamAccessToken) {
      this.isLoggedIn$.next(false);
      return of(true);
    }

    if (
      !authInfo ||
      !authInfo.accessTokenExpiresAt ||
      !authInfo.refreshTokenExpiresAt ||
      !authInfo.refreshToken
    ) {
      this.isLoggedIn$.next(false);
      return of(false);
    }

    return this.validateIDAMSession(clientId, idamAccessToken).pipe(
      switchMap((response) => {
        const isValid = response.success;

        if (isValid) {
          return of(isValid);
        }

        if (currentDateTime < (authInfo.refreshTokenExpiresAt || 0)) {
          if (!this.fetchingRefreshToken) {
            this.fetchingRefreshToken = true;

            return this.refreshIdamAccessToken().pipe(
              map((response) => {
                if (!response.success) {
                  this.clearIDAMAuthToken();
                  this.isLoggedIn$.next(false);
                }
                return response.success;
              }),
              catchError(() => {
                this.clearIDAMAuthToken();
                this.isLoggedIn$.next(false);

                return of(false);
              })
            );
          } else {
            return new Observable<boolean>((observer) => {
              let count = 0;
              const interval = setInterval(() => {
                if (count > 10 || !this.fetchingRefreshToken) {
                  clearInterval(interval);
                  observer.next(true);
                  observer.complete();
                }

                count += 1;
              }, 200);
            });
          }
        }

        this.clearIDAMAuthToken();
        this.isLoggedIn$.next(false);
        return of(isValid);
      }),
      catchError(() => {
        this.clearIDAMAuthToken();
        this.isLoggedIn$.next(false);
        return of(false);
      })
    );
  }
}
