import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CouponService } from 'src/app/views/coupons/coupons.service';

enum PermissionState {
  GRANTED = 'granted',
  DENIED = 'denied',
  PROMPT = 'prompt',
}
@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private locationSubject = new BehaviorSubject<string | null>('');
  private modalSubject = new Subject<boolean>();
  private hasNoLocationSubject = new BehaviorSubject<boolean | null>(null);
  private coordinatesSubject = new BehaviorSubject<{
    lat: number;
    lon: number;
  } | null>(null);

  showModal$ = this.modalSubject.asObservable();
  location$ = this.locationSubject.asObservable();
  coordinates$ = this.coordinatesSubject.asObservable();
  hasNoLocation$ = this.hasNoLocationSubject.asObservable();
  constructor(private couponService: CouponService) {}

  setLocation(location: string | null) {
    this.locationSubject.next(location);
  }

  setCoordinates(coordinates: { lat: number; lon: number }) {
    this.coordinatesSubject.next(coordinates);
  }

  getLocation(): string | null {
    return this.locationSubject.value;
  }

  toggleModal(val: boolean) {
    this.modalSubject.next(val);
  }

  setHasNoLocation(val: boolean) {
    this.hasNoLocationSubject.next(val);
  }

  async checkLocationPermissionAndFetch() {
    try {
      const result = await navigator.permissions.query({
        name: 'geolocation',
      });

      if (
        [PermissionState.PROMPT, PermissionState.DENIED].includes(
          result.state as PermissionState
        )
      ) {
        this.setHasNoLocation(true);
        return;
      }
      if (result.state === PermissionState.GRANTED) {
        this.setHasNoLocation(false);
        this.getCurrentLocation().subscribe(() => {});
      }
    } catch (e) {
      console.log(e);
    }
  }

  getCurrentLocation() {
    return new Observable((observer) => {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            this.setCoordinates({ lat: latitude, lon: longitude });
            this.couponService
              .getGeoCode(latitude, longitude)
              .subscribe((res) => {
                this.setLocation(res.plus_code.compound_code);
                this.setHasNoLocation(false);
              });
          },
          (error) => {
            this.toggleModal(true);
            observer.error(error);
          },
          { enableHighAccuracy: true }
        );
      }
    });
  }

  clearLocationInfo() {
    this.setHasNoLocation(false);
    this.setLocation('');
  }
}
