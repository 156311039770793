import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NativeBridgeService } from 'src/app/core/services/native-bridge.service';
import { Coupons } from 'src/app/views/coupons/interfaces';

@Component({
  selector: 'app-coupon-card',
  templateUrl: './coupon-card.component.html',
  styleUrl: './coupon-card.component.scss',
})
export class CouponCardComponent implements OnInit {
  @Input() coupon!: Coupons;

  constructor(
    private nativeBridgeService: NativeBridgeService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  getPlatform(platform: string | undefined) {
    return platform === 'platform' ? 'In-store' : 'Online';
  }

  couponDetail(coupon: Coupons) {
    this.nativeBridgeService.hideLocation();
    if (coupon.uniqueId) {
      this.router
        .navigate([`offers/coupons/${coupon.uniqueId}`])
        .catch(() => {});
    } else {
      this.router.navigate([`offers/coupons/${coupon.planId}`]).catch(() => {});
    }
  }
}
