<section  class="container" (click)="offerDetails(offer)">
    <div class="partner-icon">
      <img [src]="offer.icon" alt="img" />
    </div>
    <img class="partner-banner" [src]="offer.image" />
    <div class="text-container">
      <div class="text" [ngStyle]="{'font-size' : fontSizeForText + 'px'}">{{ offer.overline }}</div>
      <div class="expires-text">Expires {{offer.expiryDate  | date:'dd/MM/yyyy'}}</div>
    </div>
  </section>
  