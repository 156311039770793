import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { OffersNearYou } from 'src/app/views/partners/interfaces';

@Component({
  selector: 'app-offers-near-you-card',
  templateUrl: './offers-near-you-card.component.html',
  styleUrl: './offers-near-you-card.component.scss',
})
export class OffersNearYouCardComponent {
  constructor(private router: Router) {}
  @Input() offer!: OffersNearYou;
  @Input() fontSizeForText!: number;

  offerDetails(offer: OffersNearYou) {
    this.router.navigate([`offers/big-offers/${offer.id}`]).catch(() => {});
  }
}
