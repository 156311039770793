<div class="modal-overlay" *ngIf="showModal">
  <div class="popup-modal">
    <img
      src="assets/images/profile/close-large.svg"
      class="modal-close"
      (click)="closePopup()"
    />
    <div class="location-popup">
      <div class="location-header">Enter your location</div>
      <div class="search-bar">
        <input
          class="search"
          placeholder="Search"
          [formControl]="searchLocation"
          (click)="handleSearch($event)"
        />
    
        <img
          src="assets/images/common/close.svg"
          class="clear-search"
          *ngIf="searchLocation.value"
          (click)="clearSearchText()"
        />
      </div>
      <div
        class="search-current-location"
        *ngIf="!searchLocation.value && !noSearchResults"
        (click)="useCurrentLocation()"
      >
        <div class="location-icon">
          <img src="assets/images/common/location.svg" />
        </div>
        <div class="current-title">Use current location</div>
      </div>
      <div class="user-denied" *ngIf="isLocationAllowed && !loader && !searchLocation.value">Denied Geolocation. Please allow location access.</div>
      <div class="btn-loader" *ngIf="loader">
        <img
          src="assets/images/common/btn-loader.svg"
          alt="Loading..."
        />
      </div>
      <div class="no-results" *ngIf="noSearchResults && !loader">No Results Found</div>
      <div class="locations">
        <ng-container class="locations" *ngFor="let location of locations">
          <div class="location-results" (click)="selectLocation(location)">
            <div class="location-main-text">
              {{ location.structured_formatting.main_text }}
            </div>
            <div class="location-description">
              {{ location.structured_formatting.secondary_text }}
            </div>
          </div>
          <div class="hr-line"></div>
        </ng-container>
      </div>
    </div>
</div>
</div>
