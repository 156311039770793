<div class="container">
    <div class="carousel-wrapper" #carouselContainer>
      <button
        class="carousel-control left"
        (click)="previous()"
        [class.hide]="currentIndex === 0"
        [disabled]="currentIndex === 0"
      >
        <img src="assets/images/carousel/previous-arrow.svg" />
      </button>
      <div class="carousel" #carouselImages (touchstart)="handleTouchstart($event)" (touchmove)="handleTouchMove($event)"
      (touchend)="handleTouchEnd()">
        <div
          class="carousel-item"
          *ngFor="let item of items; let i = index"
          [class.active]="i === currentIndex"
          #imageElement
        >
        <ng-container *ngTemplateOutlet="templateRef; context: { $implicit: item }"></ng-container>
        </div>
      </div>
      <button
        class="carousel-control right"
        (click)="next()"
        [class.hide]="!hasNext()"
        [disabled]="!hasNext()"
      >
        <img src="assets/images/carousel/next-arrow.svg" />
      </button>
    </div>
  </div>
  