import { Component, Input, OnInit } from '@angular/core';
import { LocationService } from 'src/app/core/services/location.service';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrl: './location.component.scss',
})
export class LocationComponent implements OnInit {
  @Input() isLoggedIn!: boolean;
  selectedLocation = '';
  isLoading = true;
  constructor(private locationService: LocationService) {}

  ngOnInit(): void {
    this.setLocation();
  }

  setLocation() {
    this.locationService.location$.subscribe((loc) => {
      if (loc) {
        this.selectedLocation = loc;
        this.isLoading = false;
      } else {
        this.locationService.hasNoLocation$.subscribe((res) => {
          if (res) {
            this.isLoading = false;
            this.selectedLocation = 'Select Location';
          }
        });
      }
    });
    if (!this.isLoggedIn) {
      this.isLoading = false;
    }
  }

  openModal(): void {
    this.locationService.toggleModal(true);
  }
}
