import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MarkdownModule } from 'ngx-markdown';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { OtpComponent } from './otp/otp.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { ToastComponent } from './toast/toast.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { SkeletonDirective } from './skeleton/skeleton.directive';
import { TooltipDirective } from './tooltip/tooltip.directive';
import { BigOfferCardComponent } from './big-offer-card/big-offer-card.component';
import { PartnersCardComponent } from './partners-card/partners-card.component';
import { NoResultsComponent } from './no-results/no-results.component';
import { LocationPopupComponent } from './location-popup/location-popup.component';
import { LocationComponent } from './location/location.component';
import { OffersNearYouCardComponent } from './offers-near-you-card/offers-near-you-card.component';
import { CouponCardComponent } from './coupon-card/coupon-card.component';
import { CarouselComponent } from './carousel/carousel.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MarkdownModule,
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    TermsComponent,
    PrivacyComponent,
    OtpComponent,
    ErrorDialogComponent,
    ToastComponent,
    TooltipComponent,
    SkeletonDirective,
    TooltipDirective,
    BigOfferCardComponent,
    PartnersCardComponent,
    NoResultsComponent,
    LocationPopupComponent,
    LocationComponent,
    OffersNearYouCardComponent,
    CouponCardComponent,
    CarouselComponent,
  ],
  exports: [
    CommonModule,
    HeaderComponent,
    FooterComponent,
    TranslateModule,
    PrivacyComponent,
    TermsComponent,
    OtpComponent,
    ErrorDialogComponent,
    ToastComponent,
    MarkdownModule,
    TooltipComponent,
    SkeletonDirective,
    TooltipDirective,
    BigOfferCardComponent,
    PartnersCardComponent,
    NoResultsComponent,
    LocationPopupComponent,
    LocationComponent,
    OffersNearYouCardComponent,
    CouponCardComponent,
    CarouselComponent,
  ],
})
export class SharedModule {}
