import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  APPEND_TOKEN,
  JCN_TOKEN,
  REDIRECT_ON_UNAUTHORIZE,
} from 'src/app/constants';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import {
  FilterPayload,
  JcnToken,
  JcnTokenResponse,
  CategoriesResponse,
  CouponsResponse,
  CouponCodePayload,
  GetCouponResponse,
  ExclusiveCoupons,
  ExclusiveCouponsPayload,
  FeaturedList,
  JcnCategory,
  LocationResponse,
  GeoCode,
} from './interfaces';
@Injectable({
  providedIn: 'root',
})
export class CouponService {
  constructor(private httpClient: HttpClient) {}
  selectedCategory: JcnCategory | null = null;
  selectedFeature: FeaturedList = {
    title: 'All',
    isSelectd: true,
  };
  getJcnTokenForLoggedIn(body: JcnToken) {
    return this.httpClient.post<JcnTokenResponse>(
      environment.apiHost + `/api/user/offerstore-auth`,
      body
    );
  }

  getJcnTokenForTemporary(body: JcnToken) {
    return this.httpClient.post<JcnTokenResponse>(
      environment.apiHost + `/api/auth/offerstore`,
      body
    );
  }

  getCategories() {
    return this.httpClient.get<CategoriesResponse>(
      environment.jcnApi + `/api/client/${environment.jcnClientId}/categories`,
      {
        context: new HttpContext()
          .set(JCN_TOKEN, true)
          .set(REDIRECT_ON_UNAUTHORIZE, false),
      }
    );
  }

  applyFilter(body: FilterPayload) {
    return this.httpClient.post<CouponsResponse>(
      environment.jcnApi + `/api/client/${environment.jcnClientId}/get-coupons`,
      body,
      {
        context: new HttpContext()
          .set(JCN_TOKEN, true)
          .set(REDIRECT_ON_UNAUTHORIZE, false),
      }
    );
  }

  getCouponCode(body: CouponCodePayload) {
    return this.httpClient.post<GetCouponResponse>(
      environment.jcnApi +
        `/api/client/${environment.jcnClientId}/redeem-coupon`,
      body,
      {
        context: new HttpContext().set(JCN_TOKEN, true),
      }
    );
  }

  getExclusiveCoupons(body: ExclusiveCouponsPayload) {
    return this.httpClient.post<ExclusiveCoupons>(
      environment.jcnApi +
        `/api/client/${environment.jcnClientId}/get-winnings`,
      body,
      {
        context: new HttpContext()
          .set(JCN_TOKEN, true)
          .set(REDIRECT_ON_UNAUTHORIZE, false),
      }
    );
  }

  getLocations(input: string) {
    return this.httpClient.get<LocationResponse>(
      environment.apiHost + `/api/user/google-maps/autocomplete?input=${input}`
    );
  }

  getGeoCode(lat: number, lon: number) {
    return this.httpClient.get<GeoCode>(
      environment.apiHost + `/api/user/google-maps/geocode?latlng=${lat},${lon}`
    );
  }

  getTnc(url: string): Observable<string> {
    const context = new HttpContext().set(APPEND_TOKEN, false);
    return this.httpClient.get(url, { responseType: 'text', context });
  }

  setSelectedFeature(feature: FeaturedList) {
    this.selectedFeature = feature;
  }

  setSelectedCategories(category: JcnCategory | null) {
    this.selectedCategory = category;
  }

  getSelectedFeature(): FeaturedList {
    return this.selectedFeature;
  }

  getSelectedCategories(): JcnCategory | null {
    return this.selectedCategory;
  }
}
