<div class="image-card" *ngIf="coupon" (click)="couponDetail(coupon)">
  <img [src]="coupon.customImageUrls.webp.knowMoreCoverImgUrl" width="100%" />
  <div class="text-container">
    <div class="category-name">{{ coupon.category }}</div>
    <div class="text">{{ coupon.couponDesc }}</div>
    <div class="expires-text">
      {{ getPlatform(coupon.claimCTARedirection) }} • Expires
      {{ coupon.expiryDate | date: 'dd/MM/yyyy' }}
    </div>
  </div>
  <div *ngIf="coupon.achievement" class="exclusive-for-you">
    <div class="icon">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.3333 2.66667C11.1441 2.82227 9.25564 1.18707 7.91577 2.52163L2 8.44035L7.55687 14L13.4786 8.07529C14.8125 6.74074 13.1778 4.863 13.3333 2.66667ZM11.3073 5.95676C10.9544 6.30985 10.3861 6.30985 10.0392 5.95676C9.68631 5.60367 9.68631 5.03514 10.0392 4.68803C10.3921 4.33495 10.9604 4.33495 11.3073 4.68803C11.6602 5.04112 11.6602 5.60966 11.3073 5.95676Z"
          fill="white"
        />
      </svg>
    </div>
    <div class="text">Exclusive to you</div>
  </div>
</div>