<ng-container *ngIf="isLoggedIn && !isLoading">
  <div class="coupon-location" (click)="openModal()">
    <img src="assets/images/common/location.svg" class="location-icon" />
    <div class="location-title">{{ selectedLocation }}</div>
    <img src="assets/images/common/chevron-down.svg" class="dropdown-icon" />
  </div>
</ng-container>

<!-- skeleton -->
 <ng-container *ngIf="isLoading">
  <div class="coupon-location">
    <div class="skeleton__header"></div>
  </div>
 </ng-container>
