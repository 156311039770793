import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { LocationService } from 'src/app/core/services/location.service';
import { DOCUMENT } from '@angular/common';
import { CouponService } from '../../views/coupons/coupons.service';
import { Location as CustomLocation } from '../../views/coupons/interfaces';
@Component({
  selector: 'app-location-popup',
  templateUrl: './location-popup.component.html',
  styleUrl: './location-popup.component.scss',
})
export class LocationPopupComponent implements OnInit {
  @Input() showModal!: boolean;
  @Output() showModalChange = new EventEmitter<boolean>();
  searchLocation = new FormControl('');
  locations: CustomLocation[] = [];
  noSearchResults = false;
  loader = false;
  isLocationAllowed = false;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private couponService: CouponService,
    private locationService: LocationService
  ) {}

  ngOnInit(): void {
    this.searchLocation.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((val) => {
        if (!val) {
          this.locations = [];
          this.noSearchResults = false;
          return;
        }
        this.locations = [];
        this.getLocationOptions(val);
      });
    this.closeModal();
    this.showLocationModal();
  }

  handleSearch($event: Event) {
    $event?.preventDefault();
    $event?.stopPropagation();
    $event?.stopImmediatePropagation();
  }

  getLocationOptions(input: string) {
    this.loader = true;
    this.couponService.getLocations(input).subscribe(
      (res) => {
        this.locations = res.predictions;
        this.loader = false;
        if (!this.locations.length) {
          this.noSearchResults = true;
        } else {
          this.noSearchResults = false;
        }
      },
      (error) => {
        console.log(error);
        this.loader = false;
      }
    );
  }

  selectLocation(location: CustomLocation) {
    this.locationService.setLocation(location.structured_formatting.main_text);
    this.locationService.toggleModal(false);
    this.showModal = false;
    this.locations = [];
  }

  useCurrentLocation() {
    this.loader = true;
    this.locationService.getCurrentLocation().subscribe(
      () => {
        this.loader = false;
      },
      (error: GeolocationPositionError) => {
        this.loader = false;
        const { code, message } = error;
        if (code === 1 && message === 'User denied Geolocation') {
          this.isLocationAllowed = true;
        }
      }
    );
  }

  clearSearchText() {
    this.searchLocation.setValue('');
    this.loader = false;
    this.locations = [];
  }

  closeModal() {
    this.locationService.location$.subscribe((loc) => {
      if (loc) {
        this.showModal = false;
        this.loader = false;
        this.clearSearchText();
        this.isLocationAllowed = false;
        this.locationService.setHasNoLocation(false);
        this.showModalChange.emit(this.showModal);
        this.renderer.removeClass(this.document.body, 'no-scroll');
      }
    });
  }

  showLocationModal() {
    this.locationService.showModal$.subscribe((modal) => {
      if (modal) {
        this.showModal = modal;
        this.renderer.addClass(this.document.body, 'no-scroll');
      } else {
        this.showModal = false;
        this.renderer.removeClass(this.document.body, 'no-scroll');
      }
    });
  }

  closePopup() {
    this.showModal = false;
    this.showModalChange.emit(this.showModal);
    this.renderer.removeClass(this.document.body, 'no-scroll');
  }
}
