import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrl: './carousel.component.scss',
})
export class CarouselComponent implements OnInit, AfterViewInit, OnChanges {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() items!: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ContentChild(TemplateRef) templateRef!: TemplateRef<any>;
  currentIndex = 0;
  itemsPerView = 0;
  imageWidth = 0;

  //Gap between carousel elements
  gapBetweenElements = 0;
  currentTranslateX = 0;
  maxTranslateX = 0;
  touchStartX = 0;
  touchStartY = 0;
  isMovingHorizontally = false;
  touchEndY = 0;
  touchEndX = 0;

  @ViewChild('carouselContainer')
  carouselContainer!: ElementRef<HTMLDataElement>;
  @ViewChild('carouselImages') carouselImages!: ElementRef<HTMLDataElement>;
  @ViewChild('imageElement') imageElement!: ElementRef<HTMLDataElement>;

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (this.imageElement && this.imageElement.nativeElement) {
      this.imageWidth = this.imageElement.nativeElement.offsetWidth;
      this.updateVisibleCount();
    }
  }

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  ngOnChanges(): void {
    if (this.items.length) {
      setTimeout(() => {
        this.imageWidth = this.imageElement?.nativeElement.offsetWidth;
        this.gapBetweenElements = this.getGap();
        this.updateVisibleCount();
      }, 0);
    }
  }

  get translateValue() {
    return `translateX(-${this.currentIndex * (100 / this.items.length)}%)`;
  }

  updateVisibleCount() {
    const containerWidth = this.carouselContainer.nativeElement.offsetWidth;
    this.itemsPerView = Math.floor(
      (containerWidth + this.gapBetweenElements) /
        (this.imageWidth + this.gapBetweenElements)
    );
    this.maxTranslateX =
      this.items.length * (this.imageWidth + this.gapBetweenElements) -
      this.gapBetweenElements -
      this.carouselContainer.nativeElement.offsetWidth;
  }

  next() {
    this.currentIndex += this.itemsPerView;
    this.slideCarousel();
  }

  previous() {
    if (this.currentIndex > 0) {
      this.currentIndex -= this.itemsPerView;
      this.slideCarousel();
    }
  }

  getGap(): number {
    const firstItem =
      this.carouselImages.nativeElement.querySelector('.carousel-item');
    const secondItem = firstItem?.nextElementSibling;

    if (firstItem && secondItem) {
      const firstItemRect = firstItem.getBoundingClientRect();
      const secondItemRect = secondItem.getBoundingClientRect();

      return secondItemRect.left - firstItemRect.left - firstItemRect.width;
    }

    return 0;
  }

  slideCarousel() {
    const totalImages = this.items.length;
    this.maxTranslateX =
      totalImages * (this.imageWidth + this.gapBetweenElements) -
      this.gapBetweenElements -
      this.carouselContainer.nativeElement.offsetWidth;

    this.currentTranslateX = Math.max(
      -(this.currentIndex * (this.imageWidth + this.gapBetweenElements)),
      -this.maxTranslateX
    );

    // if (offset + maxOffset <= this.imageWidth) {
    //   offset = -maxOffset;
    // }

    if (this.currentIndex > this.items.length) {
      this.currentTranslateX = -this.maxTranslateX;
      // this.currentIndex = this.items.length - this.itemsPerView;
    }

    this.carouselImages.nativeElement.style.transform = `translateX(${this.currentTranslateX}px)`;
  }

  hasNext() {
    return this.currentTranslateX > -this.maxTranslateX;
  }

  handleTouchstart(event: TouchEvent) {
    this.touchStartX = event.touches[0].clientX;
    this.touchStartY = event.touches[0].clientY;
    // Reset the flag for each touch start
    this.isMovingHorizontally = false;
  }

  handleTouchMove(event: TouchEvent) {
    this.touchEndX = event.touches[0].clientX;
    this.touchEndY = event.touches[0].clientY;

    const deltaX = this.touchEndX - this.touchStartX;
    const deltaY = this.touchEndY - this.touchStartY;

    if (!this.isMovingHorizontally && Math.abs(deltaX) > Math.abs(deltaY)) {
      this.isMovingHorizontally = true;
    }
  }

  handleTouchEnd() {
    if (this.touchStartX !== 0 && this.touchEndX !== 0) {
      this.handleSwipe();
      this.touchStartX = 0;
      this.touchEndX = 0;
    }
  }

  handleSwipe() {
    const distance = this.touchEndX - this.touchStartX;
    let index = this.currentIndex;

    if (distance >= 15) {
      // Swipe right
      if (index == 0) {
        return;
      }
      index = this.currentIndex - this.itemsPerView;
    } else if (distance <= -15) {
      // Swipe left
      if (index == this.items.length) {
        return;
      }
      index = this.currentIndex + this.itemsPerView;
    }
    if (this.isMovingHorizontally) {
      this.handleSlide(index);
    }
  }

  handleSlide(index: number) {
    // if (index < 0) {
    //   index = this.items.length;
    // }
    // if (index >= this.items.length) {
    //   index = 1;
    // }
    this.currentIndex = index;
    this.slideCarousel();
  }
}
